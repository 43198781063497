import React, { useState, useEffect } from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '@jumbo/utils/IntlMessages'
import CmtCard from '@coremat/CmtCard'
import CmtCardContent from '@coremat/CmtCard/CmtCardContent'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js'
import Editor from './Editor'
import { config } from './configEditor'
import InputLabel from '@material-ui/core/InputLabel'
import axios from 'services/auth/jwt/config'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'
import Switch from '@material-ui/core/Switch'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import GridContainer from '@jumbo/components/GridContainer'
import { Autocomplete } from '@material-ui/lab'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Atendimento' },
  { label: 'Configuraçoes', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  pageFull: {
    width: '100%',
  },
  cardRoot: {
    height: '100%',
    marginBottom: 10,
  },
  fab: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
}))

const MySwal = withReactContent(Swal)

const DIAS_SEMANA = [
  {
    indice: 0,
    label: 'Domingo',
  },
  {
    indice: 1,
    label: 'Segunda Feira',
  },
  {
    indice: 2,
    label: 'Terça Feira',
  },
  {
    indice: 3,
    label: 'Quarta Feira',
  },
  {
    indice: 4,
    label: 'Quinta Feira',
  },
  {
    indice: 5,
    label: 'Sexta Feira',
  },
  {
    indice: 6,
    label: 'Sábado',
  },
]

const Avaliacoes = ({ match }) => {
  const classes = useStyles()
  const [editorState, setEditorState] = useState()
  const [editorStateFinalizar, setEditorStateFinalizar] = useState()
  const [editorStateHorario, setEditorStateHorario] = useState()
  const [avancarFilaAtendimento, setAvancarFilaAtendimento] = useState(false)
  const [protocoloAtendimento, setProtocoloAtendimento] = useState(false)
  const [timeAvancar, setTimeAvancar] = useState()
  const [isSendNomeAtendente, setIsSendNomeAtendente] = useState(true)
  const [notificacaoTransferencia, setNotificacaoTransferencia] = useState(true)
  const [ativarHorarioAtendimento, setHorarioAtendimento] = useState(true)
  const [tempoInatividadeAtendente, setTempoInatividade] = useState(0)
  const [isSaveMessageFromMe, SetIsSaveMessageFromMe] = useState(false)
  const [hideGroups, setHideGroups] = useState(false)
  const [isRouletteActive, setIsRouletteActive] = useState(false)
  const [selectAttendants, setSelectAttendants] = useState(false)
  const [attedantsInRoullete, setAttedantsInRoullete] = useState([])
  const [attendants, setAttendants] = useState([])
  const [rouletteMode, setRouletteMode] = useState('sequential')
  const [isAdminActiveInRoulette, setIsAdminActiveInRoulette] = useState(false)

  const [ativarAvaliacaoAtendimento, setAtivarAvaliacaoAtendimento] = useState(
    false,
  )
  const [horarios, setHorarios] = useState({
    0: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    1: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    2: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    3: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    4: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    5: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
    6: {
      enable: false,
      horario_start: '08:00',
      horario_end: '18:00',
    },
  })

  const filteredAttendants = attendants.filter(
    (attendant) =>
      !attedantsInRoullete.find(
        (attedantInRoullete) => attendant._id === attedantInRoullete._id,
      ),
  )

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('avaliacaoConfig')
      .then((success) => {
        // console.log(success)
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(success.data.mensagens_a_enviar_avaliacao_raw),
            ),
          ),
        )
        setEditorStateHorario(
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(success.data.mensagem_a_enviar_fora_horario_raw),
            ),
          ),
        )
        setEditorStateFinalizar(
          EditorState.createWithContent(
            convertFromRaw(
              JSON.parse(success.data.mensagens_a_enviar_finalizar_raw),
            ),
          ),
        )
        setTimeAvancar(success.data.timeAvancar)
        setHorarioAtendimento(success.data.isActiveHorario)
        setProtocoloAtendimento(success.data.isActiveProtocolo)
        setAtivarAvaliacaoAtendimento(success.data.isActiveAvaliacao)
        setNotificacaoTransferencia(success.data.isNotificacaoTransferencia)
        setAvancarFilaAtendimento(success.data.isAvancarFilaAtendimento)
        setIsSendNomeAtendente(success.data.isSendNomeAtendente)
        setTempoInatividade(success.data.tempoInatividadeAtendente)
        SetIsSaveMessageFromMe(success.data.isSaveMessageFromMe)
        setHideGroups(success.data.hideGroups)
        setIsRouletteActive(success.data.isRouletteActive)
        setRouletteMode(success.data.rouletteMode || rouletteMode)
        setIsAdminActiveInRoulette(
          success.data.isAdminActiveInRoulette || isAdminActiveInRoulette,
        )
        if (success.data.horarios) {
          setHorarios(success.data.horarios)
        }
        setSelectAttendants(success.data.attendantsInRoulette.length > 0)
        setAttedantsInRoullete(success.data.attendantsInRoulette)
      })
      .catch((err) => err)
    axios
      .get('/atendimento/findAtendenteByUser')
      .then((success) => {
        setAttendants(success.data.atendentes)
      })
      .catch((err) => err)
  }, [])

  const saveMensagem = () => {
    axios
      .post('avaliacaoConfig', {
        mensagens_a_enviar_avaliacao_raw: JSON.stringify(
          convertToRaw(editorState.getCurrentContent()),
        ),
        mensagens_a_enviar_finalizar_raw: JSON.stringify(
          convertToRaw(editorStateFinalizar.getCurrentContent()),
        ),
        mensagem_a_enviar_fora_horario_raw: JSON.stringify(
          convertToRaw(editorStateHorario.getCurrentContent()),
        ),
        horarios,
        timeAvancar,
        isActiveProtocolo: protocoloAtendimento,
        isActiveHorario: ativarHorarioAtendimento,
        isActiveAvaliacao: ativarAvaliacaoAtendimento,
        isNotificacaoTransferencia: notificacaoTransferencia,
        isAvancarFilaAtendimento: avancarFilaAtendimento,
        isSendNomeAtendente: isSendNomeAtendente,
        tempoInatividadeAtendente: tempoInatividadeAtendente,
        isSaveMessageFromMe,
        isRouletteActive,
        rouletteMode,
        isAdminActiveInRoulette,
        hideGroups,
        attendantsInRoulette:
          attedantsInRoullete.length > 0 && selectAttendants
            ? attedantsInRoullete.map((attedant) => attedant._id)
            : [],
      })
      .then((success) =>
        MySwal.fire('Sucesso!', 'Mensagem salva com sucesso!', 'success'),
      )
      .catch((err) => MySwal.fire('Erro!', 'Erro ao salvar mensagem!', 'error'))
  }

  const handleChangeActiveHorario = (indice) => {
    var copyHorarios = { ...horarios }
    copyHorarios[indice].enable = !copyHorarios[indice].enable
    setHorarios(copyHorarios)
  }

  const handleChangeHorario = (indice, label, new_value) => {
    // console.log(indice, label, new_value)
    var copyHorarios = { ...horarios }
    copyHorarios[indice][label] = new_value
    setHorarios(copyHorarios)
  }

  return (
    <PageContainer
      heading={<IntlMessages id="sidebar.configuracoes" />}
      breadcrumbs={breadcrumbs}
    >
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Personalize sua mensagem para obter o feedback do seu cliente:
              </InputLabel>
              <Editor
                config={config['WhatsApp']}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Personalize sua mensagem para finalizar o atendimento:
              </InputLabel>
              <Editor
                config={config['WhatsApp']}
                editorState={editorStateFinalizar}
                setEditorState={setEditorStateFinalizar}
              />
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Enviar protocolo de atendimento:
                <Switch
                  checked={protocoloAtendimento}
                  onChange={() =>
                    setProtocoloAtendimento(!protocoloAtendimento)
                  }
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Enviar notificação de transferência de atendimento:
                <Switch
                  checked={notificacaoTransferencia}
                  onChange={() =>
                    setNotificacaoTransferencia(!notificacaoTransferencia)
                  }
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Avançar para Fila de atendimento:
                <Switch
                  checked={avancarFilaAtendimento}
                  onChange={() =>
                    setAvancarFilaAtendimento(!avancarFilaAtendimento)
                  }
                />
                <br />
                {avancarFilaAtendimento && (
                  <React.Fragment>
                    <TextField
                      type="number"
                      margin="none"
                      InputProps={{ inputProps: { min: 5 } }}
                      value={timeAvancar}
                      onChange={(e) => setTimeAvancar(e.target.value)}
                    />{' '}
                    / Minutos
                    <br />
                    <span style={{ fontSize: '10px' }}>
                      Caso o cliente não forneça os dados para o robô
                      automaticamente após {timeAvancar ? timeAvancar : 30}{' '}
                      minuto(s) ele é enviado para a fila de atendimento.
                    </span>
                  </React.Fragment>
                )}
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Avaliação de atendimento
                <Switch
                  checked={ativarAvaliacaoAtendimento}
                  onChange={() =>
                    setAtivarAvaliacaoAtendimento(!ativarAvaliacaoAtendimento)
                  }
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Enviar nome do atendente:
                <Switch
                  checked={isSendNomeAtendente}
                  onChange={() => setIsSendNomeAtendente(!isSendNomeAtendente)}
                />
                <br />
                <span style={{ fontSize: '10px' }}>
                  Envia o nome do atendente que está realizando o atendimento em
                  cada mensagem.
                </span>
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Ativar Horário de atendimento:
                <Switch
                  checked={ativarHorarioAtendimento}
                  onChange={() =>
                    setHorarioAtendimento(!ativarHorarioAtendimento)
                  }
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Esconder todos os grupos de whatsapp
                <Switch
                  checked={hideGroups}
                  onChange={() => setHideGroups((prev) => !prev)}
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Salvar mensagens enviadas através do aparelho celular:
                <Switch
                  checked={isSaveMessageFromMe}
                  onChange={() => SetIsSaveMessageFromMe(!isSaveMessageFromMe)}
                />
              </InputLabel>
            </CmtCardContent>
          </CmtCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CmtCard className={classes.cardRoot}>
            <CmtCardContent>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: '10px' }}
              >
                Ativar rodízio de atendimento automático (robô):
                <Switch
                  checked={isRouletteActive}
                  onChange={() => setIsRouletteActive(!isRouletteActive)}
                />
              </InputLabel>
              {isRouletteActive ? (
                <div>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginBottom: '10px' }}
                  >
                    Incluir administradores no rodízio:
                    <Switch
                      checked={isAdminActiveInRoulette}
                      onChange={() =>
                        setIsAdminActiveInRoulette(!isAdminActiveInRoulette)
                      }
                    />{' '}
                  </InputLabel>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginBottom: '10px' }}
                  >
                    Selecionar atendentes para participar do rodízio (robô):
                    <Switch
                      checked={selectAttendants}
                      onClick={(e) => {
                        setSelectAttendants(!selectAttendants)
                      }}
                    />
                  </InputLabel>
                  {selectAttendants ? (
                    <Box
                      display="flex"
                      flexDirection={{ xs: 'column', md: 'row' }}
                      alignItems="center"
                      mb={{ xs: 6, md: 5 }}
                    >
                      <GridContainer>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            fullWidth
                            options={filteredAttendants}
                            value={attedantsInRoullete}
                            getOptionLabel={(option) => option.nome}
                            onChange={(_, newValue) => {
                              setAttedantsInRoullete(newValue)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Selecione um ou mais atendentes"
                                placeholder="Atendentes"
                              />
                            )}
                          />
                        </Grid>
                      </GridContainer>
                    </Box>
                  ) : null}
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginBottom: '10px' }}
                  >
                    Modo de operação do rodízio:
                    <br />
                    <Select
                      value={rouletteMode}
                      onChange={(v) => {
                        setRouletteMode(v.target.value)
                      }}
                    >
                      <MenuItem value="sequential">Sequencial</MenuItem>
                      <MenuItem value="balanced">Equilibrado</MenuItem>
                    </Select>
                    <br />
                    <span style={{ fontSize: '10px' }}>
                      {rouletteMode === 'sequential'
                        ? 'Distribuir as conversas de forma sequencial, independente da quantidade de conversas de cada atendente'
                        : rouletteMode === 'balanced'
                        ? 'O sistema vai distribuir as conversas com base na quantidade de conversas ativas e na fila de cada atendente'
                        : ''}
                    </span>
                  </InputLabel>
                </div>
              ) : null}
            </CmtCardContent>
          </CmtCard>
        </Grid>
        {ativarHorarioAtendimento && (
          <Grid item xs={12}>
            <CmtCard className={classes.cardRoot}>
              <CmtCardContent>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: '10px' }}
                    >
                      Horário de atendimento:
                    </InputLabel>
                  </Grid>
                  {DIAS_SEMANA.map((dia, index) => (
                    <Grid key={index} item xs={12}>
                      <Grid container spacing={0.5}>
                        <Grid item xs={4}>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: '20px' }}
                          >
                            <Switch
                              checked={horarios[dia.indice].enable}
                              onChange={() =>
                                handleChangeActiveHorario(dia.indice)
                              }
                            />
                            {dia.label}
                          </InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="time"
                            label="Horário"
                            type="time"
                            margin="none"
                            value={horarios[dia.indice].horario_start}
                            disabled={!horarios[dia.indice].enable}
                            className={classes.textField}
                            onChange={(e) =>
                              handleChangeHorario(
                                dia.indice,
                                'horario_start',
                                e.target.value,
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginTop: '20px' }}
                          >
                            á
                          </InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            id="time"
                            label="Horário"
                            type="time"
                            margin="none"
                            value={horarios[dia.indice].horario_end}
                            disabled={!horarios[dia.indice].enable}
                            className={classes.textField}
                            onChange={(e) =>
                              handleChangeHorario(
                                dia.indice,
                                'horario_end',
                                e.target.value,
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        marginBottom: '10px',
                        marginTop: '20px',
                        paddingTop: '10px',
                      }}
                    >
                      Enviar mensagem de fora de horário para conversas em
                      aberto após {tempoInatividadeAtendente} minuto(s) de
                      inatividade do atendente:
                      <br />
                      <React.Fragment>
                        <TextField
                          type="number"
                          margin="none"
                          style={{ width: '50%' }}
                          InputProps={{ inputProps: { min: 0 } }}
                          value={tempoInatividadeAtendente}
                          onChange={(e) => setTempoInatividade(e.target.value)}
                        />{' '}
                        / Minutos
                        <br />
                        <span style={{ fontSize: '10px' }}>
                          Após o horário de atendimento, o sistema verifica se a
                          última mensagem do atendente tem mais do que{' '}
                          {tempoInatividadeAtendente} minuto(s) para enviar a
                          mensagem de fora de horário.
                        </span>
                      </React.Fragment>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: '10px', marginTop: '20px' }}
                    >
                      Mensagem para atendimento fora do horário:
                    </InputLabel>
                    <Editor
                      config={config['WhatsApp']}
                      editorState={editorStateHorario}
                      setEditorState={setEditorStateHorario}
                    />
                  </Grid>
                </Grid>
              </CmtCardContent>
            </CmtCard>
          </Grid>
        )}
      </Grid>

      <Tooltip title="Salvar" aria-label="add" placement="left">
        <Fab
          className={classes.fab}
          color="secondary"
          aria-label="add"
          onClick={saveMensagem}
        >
          <SaveIcon />
        </Fab>
      </Tooltip>
    </PageContainer>
  )
}

export default Avaliacoes
