import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { requiredMessage } from '@jumbo/constants/ErrorMessages'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NotificationManager } from 'react-notifications'
import axios from 'services/auth/jwt/config'
import Loader from 'react-loaders'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}))

const filter = createFilterOptions()
const CreateSetor = ({ open, handleDialog, currentContact, setUpdate }) => {
  const classes = useStyles()

  const [fname, setFname] = useState(currentContact ? currentContact.nome : '')
  const [isActivated, setIsActivated] = useState(
    currentContact ? currentContact.isActivated : false,
  )
  const [departamentoSelected, setDepartamentoSelected] = useState(
    currentContact ? currentContact.departamento : [],
  )
  const [variaveis, setVariaveis] = useState(
    currentContact ? currentContact.variaveis : [],
  )

  const [departamentos, setDepartamentos] = useState([])

  const [fnameError, setFnameError] = useState('')
  const [departamentoError, setDepartamentoError] = useState('')
  const [loader, setLoader] = useState(true)

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('atendimento/departamento')
      .then((success) => {
        setDepartamentos(success.data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
  }, [])

  const checkValidations = () => {
    if (!fname) {
      setFnameError(requiredMessage)
    } else if (departamentoSelected.length === 0) {
      setDepartamentoError(requiredMessage)
    } else {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setLoader(true)

    if (currentContact) {
      const data = {
        _id: currentContact._id,
        nome: fname,
        isActivated,
        variaveis,
        departamento: departamentoSelected.map((dep) => dep._id),
      }

      axios
        .put('bot', data)
        .then((succes) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.success('Robô salvo com sucesso!')
        })
        .catch((error) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.error(error.response.data.message)
        })
    } else {
      const data = {
        nome: fname,
        isActivated,
        variaveis,
        departamento: departamentoSelected.map((dep) => dep._id),
      }

      axios
        .post('bot', data)
        .then((succes) => {
          setUpdate(Math.random())
          handleDialog()
          setLoader(false)
          NotificationManager.success('Robô salvo com sucesso!')
        })
        .catch((error) => {
          setLoader(false)
          handleDialog()
          NotificationManager.error(error.response.data.message)
        })
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialog}
      className={classes.dialogRoot}
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentContact ? 'Editar Robô' : 'Criar Robô'}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <AppTextInput
                fullWidth
                variant="standard"
                label="Nome"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value)
                  setFnameError('')
                }}
                helperText={fnameError}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                fullWidth
                options={departamentos}
                defaultValue={departamentoSelected}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setDepartamentoError(false)
                  setDepartamentoSelected(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione um ou mais departamentos"
                    placeholder="Departamentos"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
              {departamentoError && (
                <span style={{ color: 'red' }}>
                  Selecione pelo menos um departamento!
                </span>
              )}
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                id="variaveis"
                options={[]}
                value={variaveis}
                selectOnFocus
                clearOnBlur
                fullWidth
                handleHomeEndKeys
                onChange={(event, newValue) => {
                  setVariaveis(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Adicione as variáveis a serem usadas"
                    placeholder="Variáveis"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 6, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              <label>Ativo</label>
              <Switch
                checked={isActivated}
                onChange={() => setIsActivated(!isActivated)}
              />
            </Grid>
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={checkValidations}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateSetor

CreateSetor.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

CreateSetor.defaultProps = {
  selectedContact: null,
}
