const DEFAULT_ENVIRONMENT = 'production'

// production login: diegovin16@gmail.com senha: 123456789

const CONFIG = {
  staging: {
    API_URL: 'https://api-qa.zapresponder.com.br',
    URL_SOCKET: 'https://ws-qa.zapresponder.com.br',
    APP_URL: 'https://app-qa.zapresponder.com.br',
    CHAT_URL: 'https://chat-qa.zapresponder.com.br',
    STORAGE_URL: 'https://storage.zapresponder.com.br',
    API_STORAGE_URL: 'https://storage.zapresponder.com.br',
    AUTH_BACKEND: 'Dummy',
  },
  [DEFAULT_ENVIRONMENT]: {
    API_URL: 'https://api.zapresponder.com.br',
    URL_SOCKET: 'https://websocket.zapresponder.com.br',
    APP_URL: 'https://app.zapresponder.com.br',
    CHAT_URL: 'https://chat.zapresponder.com.br',
    STORAGE_URL: 'https://storage.zapresponder.com.br',
    API_STORAGE_URL: 'https://storage.zapresponder.com.br',
    AUTH_BACKEND: 'Dummy',
  },
  development: {
    API_URL: 'http://localhost:3001',
    URL_SOCKET: 'http://localhost:3002',
    APP_URL: 'https://app.zapresponder.com.br',
    CHAT_URL: 'https://chat.zapresponder.com.br',
    STORAGE_URL: 'https://storage-qa.zapresponder.com.br',
    API_STORAGE_URL: 'https://storage-qa.zapresponder.com.br',
    AUTH_BACKEND: 'Dummy',
  },
  cluster: {
    API_URL: 'https://api-test.zapresponder.com.br',
    URL_SOCKET: 'https://websocket-test.zapresponder.com.br',
    APP_URL: 'https://painel-prd.zapresponder.com.br',
    CHAT_URL: 'https://chat-app.zapresponder.com.br',
    STORAGE_URL: 'https://storage.zapresponder.com.br',
    API_STORAGE_URL: 'https://storage.zapresponder.com.br',
    AUTH_BACKEND: 'Dummy',
  },
}

export const URL_SOCKET =
  CONFIG[process.env.REACT_APP_ENVIRONMENT || DEFAULT_ENVIRONMENT].URL_SOCKET
export const URL =
  CONFIG[process.env.REACT_APP_ENVIRONMENT || DEFAULT_ENVIRONMENT].API_URL
export const URL_STORAGE =
  CONFIG[process.env.REACT_APP_ENVIRONMENT || DEFAULT_ENVIRONMENT].STORAGE_URL

export const URL_CHAT =
  CONFIG[process.env.REACT_APP_ENVIRONMENT || DEFAULT_ENVIRONMENT].CHAT_URL
export const URL_TUTORIAL = 'https://zapresponder.com.br/videos-ajuda'
