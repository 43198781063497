import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import GridContainer from '@jumbo/components/GridContainer'
import Grid from '@material-ui/core/Grid'
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput'
import Button from '@material-ui/core/Button'
import { requiredMessage } from '@jumbo/constants/ErrorMessages'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NotificationManager } from 'react-notifications'
import axios from 'services/auth/jwt/config'
import Loader from 'react-loaders'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { config } from './configEditor'
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js'
import Editor from './Editor'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox'
import CmtList from '../../../@coremat/CmtList'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Alert from '@material-ui/lab/Alert'
import Switch from '@material-ui/core/Switch'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {
  BOT_ETAPA_FIM,
  BOT_ETAPA_API,
  BOT_ETAPA_CHAMAR_ATENDENTE,
  BOT_ETAPA_INICIO,
  BOT_ETAPA_LISTAR_DEPARTAMENTO,
  BOT_ETAPA_MENSAGEM_SIMPLES,
  BOT_ETAPA_MENU,
  BOT_ETAPA_PERGUNTA,
} from './constants'
import { ListingItems } from './components/ListingItens'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.dark,
    },
  },
}))

const CreateSetor = ({
  open,
  setLoaderSkeleton,
  handleDialog,
  currentEtapa,
  setUpdate,
  allEtapas,
  bot,
}) => {
  const classes = useStyles()

  const [fname, setFname] = useState(
    currentEtapa ? currentEtapa.nome_etapa : '',
  )

  const [editorState, setEditorState] = useState(
    currentEtapa
      ? currentEtapa.mensagens_a_enviar_raw
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(currentEtapa.mensagens_a_enviar_raw)),
          )
        : EditorState.createEmpty()
      : EditorState.createEmpty(),
  )

  const [etapaSelected, setEtapaSelected] = useState(
    currentEtapa
      ? allEtapas.filter((row) => row._id === currentEtapa.next_etapa)[0]
      : {},
  )

  const [optionsMenu, setOptionsMenu] = useState(
    currentEtapa ? currentEtapa.options : [{ option: '', etapa: '' }],
  )

  const [tipoEtapaSelected, setTipoEtapa] = useState(
    currentEtapa ? currentEtapa.tipo_etapa : {},
  )

  const [departamentoSelected, setDepartamentoSelected] = useState(
    currentEtapa ? currentEtapa.departamentos : [],
  )

  const [atendentesSelected, setAtendentesSelected] = useState(
    currentEtapa?.atendentes?.length ? currentEtapa.atendentes : [],
  )

  const [atendentesSwitch, setAtendentesSwitch] = useState(
    currentEtapa ? currentEtapa?.atendentes?.length > 0 : false,
  )

  const [skip, setSkip] = useState(currentEtapa ? currentEtapa.skip : false)

  const [variavel, setVariavel] = useState(
    currentEtapa ? currentEtapa.variavel : '',
  )

  const [url, setUrl] = useState(currentEtapa ? currentEtapa.url : '')

  const [body, setBody] = useState(
    currentEtapa
      ? currentEtapa.body
      : `{
    "nome": "$nome"
    }`,
  )

  const [headers, setHeaders] = useState(
    currentEtapa
      ? currentEtapa.headers
      : `{
    "Content-type" : "application/json",
    "Authorization" : "Bearer asdaasdfsdf"
    }`,
  )

  const [editorConfig, setEditorConfig] = useState(config['WhatsApp'])

  const [loader, setLoader] = useState(true)
  const [opcoesEtapas, setOpcoesEtapas] = useState([])

  const [fnameError, setFnameError] = useState('')
  const [bodyError, setBodyError] = useState('')
  const [tipoEtapaError, setTipoEtapaError] = useState()
  const [errorSelected, setErrorSelected] = useState()
  const [urlError, setUrlError] = useState()
  const [departamentos, setDepartamentos] = useState([])
  const [contentError, setContentError] = useState('')
  const [atendentes, setAtendentes] = useState([])
  const [inputEmpty, setInputEmpty] = useState('')

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  const filteredAttendants = atendentes.filter(
    (attendant) =>
      !atendentesSelected.find(
        (atendenteSelected) => attendant._id === atendenteSelected._id,
      ),
  )

  const filteredDepartments = departamentos.filter(
    (department) =>
      !departamentoSelected.find((selected) => department._id === selected._id),
  )

  const editorPlaceholderMapper = {
    [BOT_ETAPA_LISTAR_DEPARTAMENTO]: 'Selecione um departamento.',
    [BOT_ETAPA_CHAMAR_ATENDENTE]: atendentesSwitch
      ? 'Selecione um atendente.'
      : 'Você será transferido para um atendente.',
    default: 'Digite a mensagem a ser enviada.',
  }
  const resolveDefaultEditorPlaceholder = (etapa) => {
    let placeholder = 'Digite a mensagem a ser enviada.'
    if (editorPlaceholderMapper[etapa]) {
      placeholder += `\nEx.: ${editorPlaceholderMapper[etapa]}`
    }
    return placeholder
  }

  const listingItemsMapper = {}
  listingItemsMapper[BOT_ETAPA_LISTAR_DEPARTAMENTO] = (
    <ListingItems
      options={filteredDepartments}
      value={departamentoSelected}
      setOption={setDepartamentoSelected}
      label="Selecione um ou mais departamentos"
      placeHolder="Departamentos"
      setInputEmpty={setInputEmpty}
      inputEmpty={inputEmpty}
    />
  )
  listingItemsMapper[BOT_ETAPA_CHAMAR_ATENDENTE] = (
    <>
      Listar atendentes:
      <Switch
        checked={atendentesSwitch}
        onChange={() => setAtendentesSwitch(!atendentesSwitch)}
      />
      {atendentesSwitch && (
        <ListingItems
          options={filteredAttendants}
          value={atendentesSelected}
          setOption={setAtendentesSelected}
          label="Selecione um ou mais atendentes"
          placeHolder="Atendentes"
          setInputEmpty={setInputEmpty}
          inputEmpty={inputEmpty}
        />
      )}
    </>
  )

  useEffect(() => {
    //buscar os tipos de etapas disponíveis
    axios
      .get('tiposEtapa/true')
      .then((success) => {
        setOpcoesEtapas(success.data)
        setLoader(false)
      })
      .catch((err) => {
        NotificationManager.error('Erro ao buscar tipos de etapas!')
        setLoader(false)
      })
  }, [])

  useEffect(() => {
    axios
      .get('atendimento/departamento')
      .then((success) => {
        setDepartamentos(success.data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
    axios
      .get('/atendimento/findAtendenteByUser')
      .then((success) => {
        setAtendentes(success.data.atendentes)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
      })
  }, [])

  const checkValidations = () => {
    if (loader) {
      return
    }
    const rawData = convertToRaw(editorState.getCurrentContent())
    let isContentEmpty = true
    for (const block of rawData.blocks) {
      if (block.text?.trim()) {
        isContentEmpty = false
      }
    }

    if (
      departamentoSelected?.length <= 0 &&
      tipoEtapaSelected.type === BOT_ETAPA_LISTAR_DEPARTAMENTO
    ) {
      setInputEmpty(true)
    } else if (
      atendentesSelected?.length <= 0 &&
      atendentesSwitch &&
      tipoEtapaSelected.type === BOT_ETAPA_CHAMAR_ATENDENTE
    ) {
      setInputEmpty(true)
    } else if (
      isContentEmpty &&
      BOT_ETAPA_LISTAR_DEPARTAMENTO === tipoEtapaSelected.type &&
      departamentoSelected?.length !== 1
    ) {
      setContentError(requiredMessage)
    } else if (
      isContentEmpty &&
      BOT_ETAPA_CHAMAR_ATENDENTE === tipoEtapaSelected.type &&
      atendentesSelected?.length !== 1
    ) {
      setContentError(requiredMessage)
    } else if (!fname && tipoEtapaSelected.type !== BOT_ETAPA_FIM) {
      setFnameError(requiredMessage)
    } else if (!url && tipoEtapaSelected.type === BOT_ETAPA_API) {
      setUrlError(requiredMessage)
    } else if (
      url &&
      tipoEtapaSelected.type === BOT_ETAPA_API &&
      !url.includes('http') &&
      !url.includes('https')
    ) {
      setUrlError('URL inválida!')
    } else if (
      tipoEtapaSelected.type === BOT_ETAPA_MENU &&
      !editorState.getCurrentContent().hasText()
    ) {
      setBodyError('Campo obrigatório!')
    } else {
      handleSubmit()
      setInputEmpty(false)
    }
  }

  const handleSubmit = () => {
    setLoader(true)
    setLoaderSkeleton(true)

    const data = {
      _id: currentEtapa ? currentEtapa._id : false,
      bot: bot._id,
      variavel,
      skip,
      headers,
      body,
      url,
      departamentos: departamentoSelected.map((dep) => dep._id),
      atendentes: atendentesSwitch
        ? atendentesSelected.map((atendente) => atendente._id)
        : [],
      nome_etapa: fname,
      tipo_etapa: tipoEtapaSelected._id,
      mensagens_a_enviar_raw: JSON.stringify(
        convertToRaw(editorState.getCurrentContent()),
      ),
      options: optionsMenu.map((row) => {
        return { option: row.option, etapa: row.etapa }
      }),
      next_etapa: etapaSelected ? etapaSelected._id : null,
    }

    axios
      .post('/bot/etapa', data)
      .then((succes) => {
        setUpdate(Math.random())
        handleDialog()
        setLoader(false)
        NotificationManager.success('Etapa salva com sucesso!')
      })
      .catch((error) => {
        setLoader(false)
        handleDialog()
        setUpdate(Math.random())
        NotificationManager.error(error.response.data.message)
      })
  }

  const onAddOptionRow = () => {
    setOptionsMenu(optionsMenu.concat({ option: '', etapa: '' }))
  }

  const onRemoveOption = (index) => {
    const updatedList = [...optionsMenu]
    updatedList.splice(index, 1)
    setOptionsMenu(updatedList)
  }

  const onAddOptionNo = (option, index) => {
    const updatedList = [...optionsMenu]
    updatedList[index].option = option
    setOptionsMenu(updatedList)
    // setPhoneError('');
  }

  const onSelectLabel = (etapa, index) => {
    const updatedList = [...optionsMenu]
    updatedList[index].etapa = etapa
    setOptionsMenu(updatedList)
  }

  const isOptionMultiple = optionsMenu.length > 1

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      className={classes.dialogRoot}
      disableBackdropClick
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentEtapa ? 'Editar Etapa' : 'Criar Etapa'}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mb={{ xs: 12, md: 5 }}
        >
          <GridContainer>
            <Grid item xs={12}>
              {tipoEtapaSelected.type != BOT_ETAPA_INICIO && (
                <FormControl fullWidth helperText={tipoEtapaError}>
                  <InputLabel id="demo-simple-select-label">
                    Selecione o tipo da etapa
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tipoEtapaSelected.value || tipoEtapaSelected._id}
                    onChange={(e) => {
                      var tipo_etapa_selected = opcoesEtapas.filter(
                        (row) => row._id === e.target.value,
                      )[0]

                      setTipoEtapa(tipo_etapa_selected)

                      setInputEmpty(false)

                      if (tipo_etapa_selected.type === BOT_ETAPA_API) {
                        setOptionsMenu([
                          { option: 'SUCESSO', etapa: '' },
                          { option: 'ERRO', etapa: '' },
                        ])
                      }
                    }}
                  >
                    {opcoesEtapas.map((row, key) => (
                      <MenuItem key={row.nome} label={row.nome} value={row._id}>
                        {row.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            {tipoEtapaSelected.type === BOT_ETAPA_FIM ? (
              <Alert severity="info">
                Essa etapa sinaliza o fim do atendimento, adicione ela para o
                bot encerrar a conversação.
              </Alert>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <AppTextInput
                    fullWidth
                    variant="standard"
                    label="Nome"
                    value={fname}
                    disabled={tipoEtapaSelected.type === BOT_ETAPA_INICIO}
                    onChange={(e) => {
                      setFname(e.target.value)
                      setFnameError('')
                    }}
                    helperText={fnameError}
                  />
                </Grid>
                {tipoEtapaSelected.type === BOT_ETAPA_API ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <AppTextInput
                        fullWidth
                        variant="standard"
                        label="URL"
                        value={url}
                        helperText={urlError}
                        onChange={(e) => {
                          setUrl(e.target.value)
                          setUrlError('')
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Headers da Requisição: </InputLabel>
                      <TextareaAutosize
                        style={{ height: 151, width: 528, marginTop: '10px' }}
                        rowsMin={8}
                        defaultValue='{
                          "Content-type" : "application/json",
                          "Authorization" : "Bearer asdaasdfsdf"
                          }'
                        value={headers}
                        onChange={(e) => setHeaders(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Body da Requisição: </InputLabel>
                      <TextareaAutosize
                        style={{ height: 151, width: 528, marginTop: '10px' }}
                        rowsMin={8}
                        defaultValue='{
                          "nome": "$nome"
                          }'
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CmtList
                        data={optionsMenu}
                        renderRow={(item, index) => (
                          <GridContainer
                            style={{ marginBottom: 12 }}
                            key={index}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ marginTop: '3px' }}
                            >
                              <AppTextInput
                                fullWidth
                                variant="standard"
                                label="Opção"
                                disabled
                                value={item.option}
                                //helperText={phoneError}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Autocomplete
                                fullWidth
                                margin="dense"
                                size="medium"
                                id="tags-standard"
                                options={allEtapas}
                                value={
                                  allEtapas.filter(
                                    (row) => row._id === item.etapa,
                                  )[0]
                                }
                                getOptionLabel={(option) => option.nome_etapa}
                                onChange={(event, newValue) => {
                                  onSelectLabel(newValue._id, index)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione a etapa"
                                    placeholder="Nome etapa"
                                  />
                                )}
                              />
                            </Grid>
                          </GridContainer>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth helperText={tipoEtapaError}>
                        <InputLabel id="demo-simple-select-label">
                          Em qual variável deseja armazenar?
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={variavel}
                          onChange={(e) => {
                            setVariavel(e.target.value)
                          }}
                        >
                          {bot.variaveis.map((varivavel, key) => (
                            <MenuItem
                              key={varivavel}
                              label={varivavel}
                              value={varivavel}
                            >
                              {varivavel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {tipoEtapaSelected.type != BOT_ETAPA_INICIO && (
                      <>
                        <Grid item xs={12}>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{
                              paddingBottom: '10px',
                            }}
                          >
                            Escreva sua mensagem:
                          </InputLabel>
                          {bodyError && (
                            <span style={{ color: 'red' }}>{bodyError}</span>
                          )}

                          <Editor
                            currentBot={bot}
                            config={editorConfig}
                            editorState={editorState}
                            setEditorState={(v) => {
                              setEditorState(v)
                              setContentError('')
                            }}
                            placeholder={resolveDefaultEditorPlaceholder(
                              tipoEtapaSelected.type,
                            )}
                          />

                          {contentError?.trim() ? (
                            <p style={{ fontSize: '12px', color: 'red' }}>
                              {contentError}
                            </p>
                          ) : null}
                        </Grid>
                      </>
                    )}
                    {[
                      BOT_ETAPA_LISTAR_DEPARTAMENTO,
                      BOT_ETAPA_CHAMAR_ATENDENTE,
                    ].includes(tipoEtapaSelected.type) && (
                      <Grid item xs={12}>
                        {listingItemsMapper[tipoEtapaSelected.type]}
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {tipoEtapaSelected.type === BOT_ETAPA_MENU ? (
              <Grid item xs={12}>
                <CmtList
                  data={optionsMenu}
                  renderRow={(item, index) => (
                    <GridContainer style={{ marginBottom: 12 }} key={index}>
                      <Grid item xs={1} sm={1} style={{ paddingTop: '40px' }}>
                        <span style={{ color: '#9a9a9b' }}>{index + 1} - </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={isOptionMultiple ? 5 : 7}
                        style={{ marginTop: '3px' }}
                      >
                        <AppTextInput
                          fullWidth
                          variant="standard"
                          label="Opção"
                          value={item.option}
                          onChange={(e) => {
                            onAddOptionNo(e.target.value, index)
                          }}
                          //helperText={phoneError}
                        />
                      </Grid>
                      <Grid item xs={isOptionMultiple ? 9 : 12} sm={4}>
                        <Autocomplete
                          fullWidth
                          margin="dense"
                          size="medium"
                          id="tags-standard"
                          options={allEtapas}
                          value={
                            allEtapas.filter((row) => row._id === item.etapa)[0]
                          }
                          getOptionLabel={(option) => option.nome_etapa}
                          onChange={(event, newValue) => {
                            onSelectLabel(newValue._id, index)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecione a etapa"
                              placeholder="Nome etapa"
                            />
                          )}
                        />
                      </Grid>
                      {isOptionMultiple && (
                        <Grid item xs={3} sm={2} style={{ marginTop: '13px' }}>
                          <IconButton onClick={() => onRemoveOption(index)}>
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </GridContainer>
                  )}
                />
                <Box
                  mb={{ xs: 6, md: 5 }}
                  display="flex"
                  alignItems="center"
                  onClick={onAddOptionRow}
                  className="pointer"
                  color="primary.main"
                >
                  <AddCircleOutlineIcon />
                  <Box ml={2}>Adicionar</Box>
                </Box>
              </Grid>
            ) : (
              ![
                BOT_ETAPA_FIM,
                BOT_ETAPA_CHAMAR_ATENDENTE,
                BOT_ETAPA_LISTAR_DEPARTAMENTO,
                BOT_ETAPA_API,
              ].includes(tipoEtapaSelected.type) && (
                <Grid item xs={12}>
                  <Autocomplete
                    id="tags-standard"
                    options={allEtapas}
                    value={etapaSelected}
                    getOptionLabel={(option) => option.nome_etapa}
                    onChange={(event, newValue) => {
                      setErrorSelected('')
                      setEtapaSelected(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione a etapa que será exibido após essa etapa ser concluída."
                        placeholder="Nome etapa"
                      />
                    )}
                  />
                  {errorSelected && (
                    <span style={{ color: 'red' }}>
                      Selecione pelo menos um contato ou grupo!
                    </span>
                  )}
                </Grid>
              )
            )}
            {tipoEtapaSelected.type === BOT_ETAPA_PERGUNTA && (
              <React.Fragment>
                <Grid item xs={12}>
                  <FormControl fullWidth helperText={tipoEtapaError}>
                    <InputLabel id="demo-simple-select-label">
                      Em qual variável deseja armazenar?
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={variavel}
                      onChange={(e) => {
                        setVariavel(e.target.value)
                      }}
                    >
                      {bot.variaveis.map((varivavel, key) => (
                        <MenuItem
                          key={varivavel}
                          label={varivavel}
                          value={varivavel}
                        >
                          {varivavel}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
            {[
              BOT_ETAPA_PERGUNTA,
              BOT_ETAPA_MENSAGEM_SIMPLES,
              BOT_ETAPA_API,
              BOT_ETAPA_MENU,
            ].includes(tipoEtapaSelected.type) && (
              <React.Fragment>
                <Grid item xs={12}>
                  <label>Pular etapa caso não seja um novo usuário?</label>
                  <Switch checked={skip} onChange={() => setSkip(!skip)} />
                </Grid>
                {[BOT_ETAPA_API, BOT_ETAPA_MENU].includes(
                  tipoEtapaSelected.type,
                ) &&
                  skip && (
                    <Grid item xs={12}>
                      <Autocomplete
                        id="tags-standard"
                        options={allEtapas}
                        value={etapaSelected}
                        getOptionLabel={(option) => option.nome_etapa}
                        onChange={(event, newValue) => {
                          setErrorSelected('')
                          setEtapaSelected(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione a etapa que será exibido após essa etapa ser concluída."
                            placeholder="Nome etapa"
                          />
                        )}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            )}
          </GridContainer>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>Cancelar</Button>
          <Box ml={2}>
            {loader ? (
              <Loader type="ball-pulse-sync" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={checkValidations}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default CreateSetor

CreateSetor.prototype = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func,
  selectedContact: PropTypes.object,
}

CreateSetor.defaultProps = {
  selectedContact: null,
}
