import React, { useState, useEffect } from 'react'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '@jumbo/utils/IntlMessages'
import Fab from '@material-ui/core/Fab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddIcon from '@material-ui/icons/Add'
import Skeleton from '@material-ui/lab/Skeleton'
import { Box } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import GridContainer from '@jumbo/components/GridContainer'
import { Grid } from '@material-ui/core'
import UserSummery from './UserSumary'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import axios from 'services/auth/jwt/config'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CreateBot from './CreateBot'
import CriarEtapas from './CriarEtapas'
import Alert from '@material-ui/lab/Alert'
import Chat from '@coremat/PopUpChat'

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Atendimento' },
  { label: 'Robô', isActive: true },
]

const useStyles = makeStyles((theme) => ({
  pageFull: {
    width: '100%',
  },
  cardRoot: {
    height: '100%',
    marginBottom: 10,
  },
  fab: {
    position: 'fixed',
    zIndex: '1000 !important',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
}))

const MySwal = withReactContent(Swal)

const Campanha = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [currentContact, setCurrentContact] = useState()
  const [userSummary, setUserSummary] = useState([])
  const [update, setUpdate] = useState()
  const [openDialogEtapas, setDialogEtapas] = useState(false)
  const [currentBot, setCurrentBot] = useState()
  const [openChatTest, setOpenChatTest] = useState(false)

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

  useEffect(() => {
    axios
      .get('bot')
      .then((succes) => {
        setUserSummary(succes.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [currentContact, update])

  const sweetAlerts = (data) => {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Tem certeza que deseja deletar esse  robô?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`bot/${data._id}`)
          .then((succes) => {
            setUpdate(Math.random())
            NotificationManager.success('Robô deletado com sucesso!')
          })
          .catch((err) => {
            setUpdate(Math.random())
            NotificationManager.error('Erro ao deletar robô!')
          })
      }
    })
  }

  const handleOpenDialog = () => {
    setOpenCreateDialog(true)
  }

  const handleDelete = (data) => {
    sweetAlerts(data)
  }

  const handleEditar = (data) => {
    setCurrentBot(data)
    setDialogEtapas(true)
  }

  const onCloseEtapa = () => {
    setCurrentBot(null)
    setDialogEtapas(false)
  }

  const handleConfigurar = (data) => {
    setCurrentContact(data)
    setOpenCreateDialog(true)
  }

  const onCloseComposeDialog = () => {
    setCurrentContact(null)
    setOpenCreateDialog(false)
  }

  const handleChatTest = (data) => {
    setCurrentBot(data)
    setOpenChatTest(true)
  }

  const handleCloseChatTest = () => {
    setCurrentBot(null)
    setOpenChatTest(false)
  }

  return (
    <React.Fragment>
      <PageContainer
        heading={<IntlMessages id="pages.atendimento.robo" />}
        breadcrumbs={breadcrumbs}
      >
        {/* <Box className={classes.pageFull}>
          <GridContainer>
            <Alert severity="warning">
              Robô em Manutenção, Previsão de retorno 07/03/2022 08:00
            </Alert>
          </GridContainer>
        </Box>
      </PageContainer>
  */}

        <Box className={classes.pageFull}>
          <GridContainer>
            {loading ? (
              <Grid item xs={12}>
                <Box>
                  <Skeleton variant="rect" height={118} />
                </Box>
              </Grid>
            ) : userSummary.length === 0 ? (
              <Alert severity="info">Para começar crie um Bot (+)</Alert>
            ) : (
              userSummary.map((row, key) => (
                <Grid item xs={12}>
                  <UserSummery
                    key={key}
                    robo={row}
                    handleCloseChatTest={handleCloseChatTest}
                    handleChatTest={handleChatTest}
                    handleEditar={handleEditar}
                    handleDelete={handleDelete}
                    handleConfigurar={handleConfigurar}
                    setCurrentContact={setCurrentContact}
                  />
                </Grid>
              ))
            )}
          </GridContainer>
        </Box>
        {openCreateDialog && (
          <CreateBot
            userSummary={userSummary}
            setUserSummary={setUserSummary}
            open={openCreateDialog}
            currentContact={currentContact}
            handleDialog={onCloseComposeDialog}
            setUpdate={setUpdate}
          />
        )}

        <Tooltip title="Adicionar" aria-label="add" placement="left">
          <Fab
            className={classes.fab}
            color="secondary"
            aria-label="edit"
            onClick={() => {
              handleOpenDialog()
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </PageContainer>
      <NotificationContainer />
      {openDialogEtapas && (
        <CriarEtapas
          setUpdate={setUpdate}
          open={openDialogEtapas}
          handleDialog={onCloseEtapa}
          bot={currentBot}
        />
      )}
      {openChatTest && (
        <Chat
          openChatTest={openChatTest}
          currentBot={currentBot}
          handleCloseChatTest={handleCloseChatTest}
        />
      )}
    </React.Fragment>
  )
}

export default Campanha
